import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import burleson from '../../assets/images/burleson-tx-img.jpg'

class BurlesonTX extends React.Component {
  render() {
    const siteTitle =
      'Burleson Gate Repair & Installation Services - J & J Gates Service And Design'
    const siteDescription =
      'J&J Gates Service & Design is Located in Burleson, TX! Contact Us at (817) 435-1513 For a Free Estimate on Gate Design, Installation and Services'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">
              Burleson, TX Gate Repair & Installation Services
            </h1>
            <hr />
          </div>
        </div>
        <div className="container gates-container">
          <div className="row">
            <div className="col-md-12">
              <h4>Our Burleson Location</h4>
              <p className="font-weight-bold">
                J & J Gates Service And Design
                <br /> 12308 Rendon Rd Ste. 108
                <br /> Burleson, Tx. 76028
                <br />
                <a href="tel:8174662794">(817)-466-2794</a>
              </p>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p>
                Get a custom gate in Burleson, TX from the gate design and
                automation experts at J&J Gates! We’ve been in the business of
                building and installing custom gates and fences for over ten
                years so you can be rest assured you’ll receive the best
                workmanship around.
              </p>
              <p>
                Automatic driveway gates serve as extra protection for your
                property. They’re also great to keep kids and pets near your
                home with worrying about them wandering too close to the street.
                Just check out this picture of an automatic driveway gate in
                action:
              </p>
              <p>
                With driveway gates, you have absolutely no limits on what you
                can do. You can get simple gates for just a few thousand bucks
                that don’t look the best but do provide you with more security.
                Or, you can go all out and spend as much as you want on a
                gorgeous gate that completely blows you and everyone who sees it
                (including your nosy neighbors) away. Anything is possible.bu
              </p>
            </div>
            <div className="col-md-4">
              <LazyLoadImage
                src={burleson}
                className="menu-gate"
                alt="Burleson TX"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>Why Choose Us to Install Your Automatic Driveway Gate?</h4>
              <p>
                We’re not the only company in Burleson, TX that installs
                driveway gates. But you can absolutely trust the service and
                quality of work you get. Just Google “J&J Garage and Gate
                Burleson, TX BBB”, and we’ll be the first result that pops up.
                While not officially BBB accredited, they still maintain a
                rating on us (like every local business), and you’ll be
                impressed with our rating. If you subscribe to Angie’s list,
                research us there as well.
              </p>
              <p>
                To find out what you want and how to get it done, we need to
                visit your property and talk to you in person. You’re in luck.
                You get a free estimate where you can discuss your vision for
                your gate, ask all your questions, and get to know if we’re the
                right contractor for you.
              </p>
              <p>
                To get your free estimate, just call{' '}
                <a href="tel:8174662794">(817)-466-2794</a> or{' '}
                <Link to="/contact-us">contact us online today</Link>.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>Frequently Asked Questions</h4>
              <h5>
                1. Why would I want to add an automatic driveway gate to my
                home?
              </h5>
              <p>
                Homeowners do so for a number of reasons. They like to feel like
                they live in their dream home. It’s also nice to feel a little
                superior to your neighbors too. You might also get one for
                security reasons, to boost your property value, or simply to
                increase the beauty of your property. Your insurer may also give
                you a discount, but you’ll have to check with them on that.
              </p>
              <h5>2. What maintenance do automatic driveway gates need?</h5>
              <p>
                They need their fair share. You can certainly do it yourself,
                but it’s not the most fun work. J&J can do the maintenance for
                you. Cleaning and lubing parts are the brunt of the work. Some
                parts, like belts, if your gate has them, may need regular
                replacing. This takes a visit or two each year.
              </p>
              <h5>
                3. What if something goes wrong during the gate installation
                process?
              </h5>
              <p>
                Relax. We’ll make it right. It’s one of the things that has
                helped us keep an excellent BBB rating over the years.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4>Ready To Install Your Automatic Driveway Gate?</h4>
              <p>
                Have all your questions answered? Want to get to know us a
                little better? Did you think of some other questions?
              </p>
              <p>
                Call J&J at <a href="tel:8174662794">(817)-466-2794</a> or{' '}
                <Link to="/contact-us">contact us online today</Link> to learn
                more and to get your free estimate.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BurlesonTX

export const BurlesonTXPageQuery = graphql`
  query BurlesonTXPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
